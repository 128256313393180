











import { Component, Prop } from "vue-property-decorator";

import { ValidationMessageCpt, BaseInputComponent } from ".";

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputSwitchCpt extends BaseInputComponent {
  @Prop({ default: "Yes" }) activeText: string;
  @Prop({ default: "No" }) inactiveText: string;
}
