























import { Component, Prop } from "vue-property-decorator";

import { ValidationMessageCpt, BaseInputComponent } from ".";

import { GridModel, DataFilter } from "@/core/models";

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputSearchFieldCpt extends BaseInputComponent {
  @Prop({ default: "text" }) type: string;
  @Prop() autofocus?: boolean;
  @Prop() autosize?: any;
  @Prop() grid: GridModel<any, DataFilter>;
  debounce: number | undefined;
  internalModel: any = (this.model as any)[this.prop];

  onInternalChange() {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.grid.onTextSearch(this.internalModel, this.prop);
    }, 350);
  }

  onInternalClear() {
    this.internalModel = "";
    this.onInternalChange();
  }
}
