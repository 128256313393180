














import { Vue, Component, Prop } from "vue-property-decorator";

import { GridModel, SimpleLookupModel, DataFilter } from "@/core/models";

@Component
export default class GridSelectFilterCpt extends Vue {
  @Prop() grid: GridModel<any, DataFilter>;
  @Prop() prop: string;
  @Prop() lookups: SimpleLookupModel[];
  @Prop({ default: "FILTER PLACEHOLDER" }) placeholder: string;
  @Prop({ default: false }) filterable: boolean;

  onChange(value: any) {
    this.$forceUpdate();
    this.grid.onFilter();
  }
}
