



















import { Component, Prop } from "vue-property-decorator";

import { ValidationMessageCpt, BaseInputComponent } from ".";

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputNumberCpt extends BaseInputComponent {
  @Prop() min?: number;
  @Prop() max?: number;
  @Prop() controls?: boolean;
}
