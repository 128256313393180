











































import { Component, Prop } from "vue-property-decorator";
import _ from "lodash";

import { MultiInputSumModel } from "@/core/models";
import { ValidationMessageCpt, BaseInputComponent } from ".";

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class MultiInputSumCpt extends BaseInputComponent {
  // This component expects model[prop] to be of MultiInputSumCpt type

  get inputModel() {
    return (this.model as any)[this.prop] as MultiInputSumModel;
  }

  get isExpectedTotalReached() {
    return this.currentSum === this.inputModel.expectedTotal;
  }

  get currentSum() {
    return _.sum(_.map(this.inputModel.items, item => item.value));
  }

  get leftToDistribute() {
    return this.inputModel.expectedTotal - this.currentSum;
  }

  calculateMax(itemValue: number) {
    return this.leftToDistribute + itemValue;
  }
}
