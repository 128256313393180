


























import { Component, Prop } from "vue-property-decorator";

import { ValidationMessageCpt, BaseInputComponent } from ".";

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputDateCpt extends BaseInputComponent {
  @Prop({ default: "yyyy-MM-dd" }) valueFormat: string;
  @Prop({ default: "dd MMM yyyy" }) format: string;
  @Prop({ default: "" }) placeholder: string;
  @Prop({ default: "" }) startPlaceholder: string;
  @Prop({ default: "" }) endPlaceholder: string;
  @Prop({ default: "" }) rangeSeparator: string;
  @Prop({ default: "date" }) type: string;
  @Prop({ default: false }) clearable: boolean;
  @Prop() pickerOptions?: any;

  change(value: any) {
    super.onChange(value);
  }
}
