






















































import { Component, Prop } from "vue-property-decorator";
import _ from "lodash";

import { ValidationMessageCpt, SelectableInputComponent } from ".";

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputSelectWithIconButtonCpt extends SelectableInputComponent {
  @Prop() onClear: () => void;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: false }) filterable: boolean;
  @Prop({ default: true }) showIconButton: boolean;
  @Prop({ default: "el-icon-plus" }) icon: string;
  @Prop({ default: "Add new item" }) iconButtonTooltip: string;

  get buttonsColumnSpan() {
    let span = 0;
    if (this.showIconButton) {
      span += 4;
    }
    if (!!this.onClear && !!(this.model as any)[this.prop]) {
      span += 4;
    }

    return span;
  }

  get inputColumnSpan() {
    switch (this.buttonsColumnSpan) {
      case 0:
        return 24;
      case 4:
        return 20;
      default:
        return 16;
    }
  }

  addButtonClicked() {
    this.$emit("addButtonClicked");
  }

  clearSelection() {
    // NOTE: If using the onClear, the same kind of deletion and force update needs
    // to be done in the parent component as well to clean up the original "model"
    delete (this.model as any)[this.prop];
    this.$forceUpdate();
    this.onClear();
  }
}
