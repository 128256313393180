











import { Vue, Component, Prop } from "vue-property-decorator";

import { GridModel, DataFilter, PagedCollection, PagedCollectionFilter } from "@/core/models";

@Component
export default class GridSearchCpt extends Vue {
  @Prop() grid: GridModel<any, DataFilter>;
  @Prop({ default: "Search" }) placeholder?: string;
  dataFilter = new DataFilter();
  debounce: number | undefined;

  created() {
    this.dataFilter = this.grid.filter;
  }

  onChange() {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.grid.onSearch(this.dataFilter);
    }, 350);
  }

  onClear() {
    this.grid.onSearch("");
  }
}
