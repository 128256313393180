export const roles = {
  Architect: "Architect",
  Promoter: "Promoter",
  Contractor: "Main Contractor",
};

export const roleFlag = {
  Default: "cis-icon-flag",
  Architect: "cis-icon-compass",
  Promoter: "cis-icon-flag",
  Contractor: "cis-icon-paint",
  "Main Contractor": "cis-icon-paint",
  "Planning Consultant": "cis-icon-flag",
  "Civil Engineer": "cis-icon-flag",
};
