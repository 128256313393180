

































import AppVue from "../../AppVue.vue";
import { Component, Prop } from "vue-property-decorator";
import { property } from "lodash";

@Component({
  components: {},
})
export default class WatchedButton extends AppVue {
  @Prop({ default: false }) loading: boolean;
  @Prop({ default: false }) isWatched: boolean;
  @Prop() modelId: number;
  @Prop({ default: "Add to watchlist" }) addTextToolTip: string;
  @Prop({ default: "Remove from watchlist" }) removeTextToolTip: string;
  @Prop({ default: "Start tracking" }) addText: string;
  @Prop({ default: "Stop tracking" }) removeText: string;
  @Prop({ default: "text" }) buttonType: string;
  clickedTimeout = 500; // msecs
  isClicked = false;
  size = "medium";
  toggleWatchList() {
    if (!this.isClicked) {
      this.isClicked = true;
      this.$emit("click", this.modelId);
      setTimeout(() => (this.isClicked = false), this.clickedTimeout);
    }
  }
  get watchlistTooltip() {
    if (this.isWatched) {
      return this.removeTextToolTip;
    }
    return this.addTextToolTip;
  }
  mounted() {
    if (this.$isMobile()) {
      this.size = "mini";
    }
  }
}
