











import { Vue, Component, Prop } from "vue-property-decorator";

import { GridModel } from "@/core/models";

@Component
export default class GridPagerCpt extends Vue {
  @Prop() grid: GridModel<any, any>;

  created() {
    this.$eventHub.$on("GRID_DATA_UPDATED", () => {
      this.$forceUpdate();
    });
  }

  onChange(page: number) {
    this.grid.goToPage(page);
  }
}
