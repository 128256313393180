












import { Vue, Component, Prop } from "vue-property-decorator";

import { Validatable } from "@/core/models";
import { ValidationHintCpt } from ".";

@Component({
  components: {
    ValidationHintCpt,
  },
})
export default class SubmitButtonCpt extends Vue {
  @Prop() model: Validatable;
  @Prop() loading: boolean;
  @Prop() disabled: boolean;
  @Prop({ default: "Submit" }) text: string;
  @Prop({ default: "medium" }) size: string;
}
