


















import { Component, Prop } from "vue-property-decorator";

import { ValidationMessageCpt, BaseInputComponent } from ".";

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputFieldCpt extends BaseInputComponent {
  @Prop({ default: "text" }) type: string;
  @Prop() autofocus?: boolean;
  @Prop() autosize?: any;
}
