

















import { Component, Prop } from "vue-property-decorator";

import { ValidationMessageCpt, SelectableInputComponent } from ".";

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputRadioCpt extends SelectableInputComponent {}
