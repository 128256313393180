




import { Component, Vue, Prop } from "vue-property-decorator";
import { IconService } from "@/core/services";

@Component
export default class SectorIconCpt extends Vue {
  @Prop({ default: "" }) sectorName: string;
  @Prop({ default: "" }) categoryName: string;
  @Prop({ default: 0 }) sectorId: number;
  @Prop({ default: "" }) roleName: string;

  get iconClass() {
    if (this.sectorName) {
      return IconService.getIconByName(this.sectorName);
    }
    if (this.roleName) {
      return IconService.getIconByRoleName(this.roleName);
    }
    if (this.sectorId) {
      return IconService.getIconById(this.sectorId);
    }
    if (this.categoryName) {
      return IconService.getIconByCategoryName(this.categoryName);
    }
    return "cis-icon-building";
  }
}
