























import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import {} from "@/core/services";
import {} from "@/core/components";

import AppVue from "@/AppVue.vue";
import "./../../../styles/variables.scss";
import { property } from "lodash";

@Component({
  components: {},
})
export default class RadioButtonTristateCpt extends AppVue {
  model = "Off";
  @Prop({ default: "" }) title: string;
  @Prop({ default: "" }) cssClass: string;
  @Prop({ default: "" }) description: string;
  @Prop({ default: () => "Off" }) initialValue: string;
  @Prop({ default: () => ["Off", "Daily", "Weekly"] }) values: string[];
  @Prop({ default: false }) disabled: boolean;
  @Watch("initialValue")
  updateModel(newValue: string) {
    this.model = newValue;
  }

  modelChanged(newValue: string) {
    this.$emit("RadioGroupUpdate", newValue);
  }
  async created() {}

  async mounted() {
    this.model = this.initialValue ?? this.model;
  }
}
