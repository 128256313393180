




























import { Component, Prop } from "vue-property-decorator";
import _ from "lodash";

import { ValidationMessageCpt, SelectableInputComponent } from ".";

@Component({
  components: {
    ValidationMessageCpt,
  },
})
export default class InputSelectCpt extends SelectableInputComponent {
  @Prop() onClear: () => void;
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: false }) filterable: boolean;
  @Prop({ default: false }) addAll: boolean;

  addAllItems() {
    (this.model as any)[this.prop] = _.map(this.lookups, this.lookupIdProp);
    this.$forceUpdate();
  }

  clearSelection() {
    // NOTE: If using the onClear, the same kind of deletion and force update needs
    // to be done in the parent component as well to clean up the original "model"
    delete (this.model as any)[this.prop];
    this.$forceUpdate();
    this.onClear();
  }
}
