
import AppVue from "@/AppVue.vue";

export default abstract class BaseFormComponent extends AppVue {
  submitInProgress = false;
  abstract onSubmitForm(): Promise<void>;

  async submitForm() {
    if (!this.onSubmitForm) {
      throw new Error("onSubmitForm not implemented");
    }

    this.submitInProgress = true;

    try {
      await this.onSubmitForm();
    } finally {
      this.submitInProgress = false;
    }
  }

  protected onSuccess(route?: string, customMessage?: string) {
    this.notify("Success", customMessage || "Item saved.", "success");

    if (route) {
      this.$router.push(route).catch(() => {});
    }
  }
}
