
import { Prop } from "vue-property-decorator";
import _ from "lodash";

import AppVue from "@/AppVue.vue";
import { GridModel, PagedCollection, PagedCollectionFilter, DataFilter } from "@/core/models";
import { GridService } from "@/core/services";

export default abstract class BaseGridComponent<T, TDataFilter extends DataFilter> extends AppVue {
  grid: GridModel<T, TDataFilter>;

  /**
   * Initializes the grid component. This method is supposed to be called from
   * the class that's implementing the BaseGridComponent class.
   * Builds a grid object that contains filter, collection and event handlers.
   *
   * @param filterMethod - Filter method to be called on filter change to fetch new data.
   * @param routeName - Route name to transition to on filter change (optional).
   * @param filter - Initial grid filter (optional, if not passed, default queryParams will be used).
   */
  protected initialize(
    filterMethod: (filter: PagedCollectionFilter & TDataFilter) => Promise<PagedCollection<T>>,
    routeName?: string,
    initFilter?: PagedCollectionFilter & TDataFilter,
  ) {
    const filter = this.defaultQueryParamsFactory(initFilter);

    // Initialize the shared grid model
    this.grid = GridService.gridFactory<T, TDataFilter>(filter);

    // Prevent duplication of events, will potentially have to
    // improve this in the future, but for now this is ok
    this.$eventHub.$off("GRID_FILTER_UPDATED");

    // On grid interaction, update the route and re-fetch data
    this.$eventHub.$on("GRID_FILTER_UPDATED", () => {
      this.updateRoute(filter, routeName);
      this.filterCollection(filterMethod, filter);
    });

    // Initialize the first fetch
    this.$eventHub.$emit("GRID_FILTER_UPDATED");
  }

  /**
   * Builds default query params filter. If any additional params need to be added, or any of the
   * default ones changed - override/extend the query params passed to the initializeGrid() in the
   * concrete grid implementation class.
   */
  private defaultQueryParamsFactory(initFilter?: PagedCollectionFilter & TDataFilter) {
    return GridService.pageQueryParamsFactory<PagedCollectionFilter & TDataFilter>(this.$route.query, initFilter);
  }

  /**
   * Updates the current grid collection route with currently defined query params (based on filter).
   * @param filter - Current grid collection filter.
   * @param route - Route name to transition to on filter change.
   */
  private updateRoute(filter: PagedCollectionFilter & TDataFilter, routeName?: string) {
    if (routeName) {
      const definedParams = this.getDefinedParams(filter);

      this.$router.push({ name: routeName, query: definedParams as any }).catch((error) => {
        // SEE: https://stackoverflow.com/a/59431264/413785
        if (error.name !== "NavigationDuplicated") {
          throw error;
        }
      });
    }
  }

  /**
   * Handles the actual data fetching/filtering.
   * @param filterMethod - Filter method to be called on filter change to fetch new data.
   * @param filter - Current grid collection filter.
   */
  private filterCollection(
    filterMethod: (filter: PagedCollectionFilter & TDataFilter) => Promise<PagedCollection<T>>,
    filter: PagedCollectionFilter & TDataFilter,
  ) {
    const loading = this.$loading({
      lock: true,
      text: "Getting data...",
    });
    const definedParams = this.getDefinedParams(filter);
    filterMethod(definedParams)
      .then((collection) => {
        _.extend(this.grid.collection, collection);
      })
      .catch(() => {
        _.extend(this.grid.collection, {
          pageCount: 0,
          recordCount: 0,
          items: [],
        });
      })
      .finally(() => {
        loading.close();
        this.$eventHub.$emit("GRID_DATA_UPDATED");
        this.$forceUpdate();
      });
  }

  private getDefinedParams(filter: PagedCollectionFilter & TDataFilter) {
    // Only filter out params which are actually set, ignore unused ones
    return _.pickBy(this.grid.filter, _.identity) as PagedCollectionFilter & TDataFilter;
  }
}
