









import { Component, Prop } from "vue-property-decorator";

import { InputFieldCpt, BaseInputComponent } from ".";

@Component({
  components: {
    InputFieldCpt,
  },
})
export default class InputTextareaCpt extends BaseInputComponent {
  @Prop() autofocus?: boolean;
  @Prop({ default: 3 }) minRows?: number;
  @Prop({ default: 10 }) maxRows?: number;
}
